<template>
<div class="bills-content">
  <a-card class="mt-2">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0"><font-awesome-icon icon="money-check"
                                               class="mt-1 mr-1 font-size-24"/> {{ $t('common.payouts') }}</span>
    </div>
    <!-- First row -->
    <div class="d-flex mb-3">
      <!-- Select agent -->
      <div class="ml-0">
        <a-select v-model="accountId" :placeholder="$t('placeholders.selectAgent')" class="width-150"
                  @change="selectAccount">
          <a-select-option :value="0">
            [{{ $t('pre.all') }} {{ $t('common.agents') }}]
          </a-select-option>
          <a-select-option v-for="account in accountsData" :key="account.id">
            {{ account.name }}
          </a-select-option>
        </a-select>
      </div>
      <!-- Select agent -->
      <!-- Select date -->
      <div class="ml-3">
        <a-button-group class="range-picker-with-btn">
          <a-range-picker
                  :show-time="{ format: 'HH:mm' }"
                  v-model="datetime"
                  format="YYYY-MM-DD HH:mm"
                  :allowClear="false"
                  @ok="updateTable"
          />
          <a-button @click="toggleUseDatetime" :type="useDatetime ? 'primary' : ''">
            <a-icon type="search"/>
          </a-button>
        </a-button-group>
      </div>
      <!-- Select date -->
      <!-- Select search -->
      <div class="" style="margin-left: auto;">
        <a-input-search
                v-model="search"
                :placeholder="$t('placeholders.selectSearch')"
                style="width: 300px"
                allowClear
                @search="updateTable"
        >
        </a-input-search>
      </div>
      <!-- Select search -->
    </div>
    <!-- First row -->
    <!-- Second row -->
    <div class="mt-1 mb-3">
      <a-row :gutter="16" type="flex" align="bottom">
        <a-col :span="4">
          <a-statistic :title="`${$t('common.total')} ${$t('common.payouts')}`" :value="statData.count"
                       style="margin-right: 50px">
          </a-statistic>
        </a-col>
        <a-col :span="5">
          <a-statistic :title="`${$t('common.sum')} USDT`" :value="statData.sum_usd ? statData.sum_usd : 0"
                       prefix="" style="margin-right: 50px">
          </a-statistic>
        </a-col>
      </a-row>
    </div>
    <!-- Second row -->
    <a-table
            :columns="table.columns"
            :dataSource="table.rows"
            :row-key="record => record.id"
            :pagination="table.pagination"
            :loading="table.loading"
            @change="handleTableChange"
    >
      <template v-slot:id="id, record">
        <span>{{ id }}</span>
        <span class="small d-block text-gray-5">ext_id: {{ record.ext_id.slice(0, 5).concat('...') }}
                <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right" class="">
                    <a href="javascript:">
                        <a-icon type="copy" class="text-gray-5" @click="clipboardHandle(record.ext_id)"/>
                    </a>
                </a-tooltip>
                </span>
      </template>
      <template v-slot:created_at="created_at">
        <span>{{ $moment.parseZone(created_at).format('DD.MM.YY') }}</span>
        <span class="small d-block text-gray-5"><a-icon
                type="clock-circle"/> {{ $moment.parseZone(created_at).format('HH:mm') }}</span>
      </template>
      <template v-slot:account="account">
          <span class="mr-1">
            <img v-if="account.sex === 'male'" src="/resources/images/avatars/user-agent-m.png" width="18"/>
            <img v-else src="/resources/images/avatars/user-agent-w.png" width="18"/>
          </span>
        <span v-if="account">{{ account.name }}</span>
      </template>
      <template v-slot:address="address">
        <span v-if="address.type === 'BSC'">
          <a :href="`https://bscscan.com/address/${address.address}#tokentxns`" target="_blank">
            {{ address.address }}
          </a>
        </span>
        <span v-else>
          {{ address.address }}
        </span>
        <span class="d-block text-gray-5 small">{{ address.type }}</span>
      </template>
      <template v-slot:status="status">
        <template v-if="status === 'CREATED'">
          <a-tooltip title="Created" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'PENDING'">
          <a-tooltip title="Created" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'PROCESSING'">
          <a-tooltip title="Processing" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'PAID'">
          <a-tooltip title="Paid" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-success"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'EXPIRED'">
          <a-tooltip title="Expired" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'CANCELED'">
          <a-tooltip title="Canceled" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'EXTENDED'">
          <a-tooltip title="Extended" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
          </a-tooltip>
        </template>
        <template v-else-if="status === 'FAILED'">
          <a-tooltip title="Failed" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
          </a-tooltip>
        </template>
      </template>
    </a-table>
  </a-card>
</div>
</template>

<script>
import { reactive } from 'vue'
import apiClient from '@/services/axios'

export default {
  name: 'index',
  data() {
    return {
      useDatetime: true,
      statData: {
        count: 0,
        sum_usd: 0,
      },
      accountsData: [],
      accountId: 0,
      search: '',
      table: reactive({
        rows: [],
        columns: [
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            scopedSlots: { customRender: 'id' },
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            scopedSlots: { customRender: 'created_at' },
          },
          {
            title: 'Agent',
            dataIndex: 'agent',
            key: 'agent',
            scopedSlots: { customRender: 'account' },
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            scopedSlots: { customRender: 'amount' },
          },
          {
            title: 'Address',
            dataIndex: 'wallet',
            key: 'wallet',
            scopedSlots: { customRender: 'address' },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            scopedSlots: { customRender: 'status' },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: -1,
        },
      }),
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
    }
  },
  async mounted() {
    await this.getAccounts()
    await this.fetchPayouts()
    await this.getStatData()
  },
  methods: {
    toggleUseDatetime() {
      this.useDatetime = !this.useDatetime
      this.updateTable()
    },
    async getStatData() {
      try {
        const url = '/admin/accounts/payouts/analytics/digits'

        const payload = {
          agent_id: this.accountId,
          search: this.search,
        }

        payload.from_date = this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        payload.to_date = this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

        const response = await apiClient.get(url, {
          params: { ...payload },
        })
        this.statData = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}...`,
      })
    },
    async selectAccount() {
      this.table.pagination.current = 1
      await this.fetchPayouts()
    },
    handleTableChange(pagination) {
      this.table.pagination.current = pagination.current
      this.fetchPayouts()
    },
    async getAccounts() {
      try {
        const url = '/admin/accounts?short'
        const response = await apiClient.get(url)
        this.accountsData = response.data.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async fetchPayouts() {
      try {
        this.table.loading = true

        const payload = {
          page: this.table.pagination.current,
          results: this.table.pagination.pageSize,
          agent_id: this.accountId,
          search: this.search,
        }

        payload.from_date = this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        payload.to_date = this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

        const response = await apiClient.get('/admin/accounts/payouts', { params: payload })
        this.table.rows = response.data.data
        this.table.pagination.total = response.data.meta.total
      } catch (e) {
        console.log(e)
      } finally {
        this.table.loading = false
      }
    },
    async updateTable() {
      this.table.pagination.current = 1
      await this.fetchPayouts()
      await this.getStatData()
    },
  },
}
</script>
<style>
</style>
